// Evenements
body.page-evenements, body.page-gebeurtenissen, body.page-events {
  .view {
    padding-bottom: 125px;
  }
  .view-content {
    padding-top: 45px;
  }
  .attachment-before {
    padding-top: 80px;
    .view, .view-content {
      padding: 0;
    }
  }
  .views-exposed-widgets {
    display: flex;
    @media (max-width: @screen-md-min) {
      flex-wrap: wrap;
    }
    > div {
      margin-right: 10px;
      &:nth-child(2) {
        select {
          width: 230px;
        }
      }
    }
  }
}
body.page-gebeurtenissen {
  .views-exposed-widgets {
    > div {
      &:nth-child(1) {
        select {
          width: 230px;
        }
      }
    }
  }
}

.outer_btn {
  margin: auto;
  margin-top: 60px;
  text-align: center;
  width: 100%;
  display: inline-block;
}
.item--event.page-events {
  height: 350px;
}
