@media print {
  .os-animation {
    opacity: 1 !important;
  }
  #header {
    position: relative !important;
    top: 0 !important;
  }
  #cat-extranet, body.not-front .cta-contact, #logos, #footer .newsletter, #footer .footer-bottom, #footer .footer-top .footer-menu, #footer .footer-top .member {
    display: none !important;
  }
  #banner {
    height: auto !important;
  }
}