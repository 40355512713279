// Footer
#logos {
	padding-top: 70px;
	padding-bottom: 130px;
	text-align: center;
	z-index: 99;
	position: relative;
	.swiperLogo {
		width: 100%;
		height: auto;
		overflow: hidden;
	}
	.container.md {
		position: relative;
	}
	.swiper-button-prev {
		left: -50px;
		margin-top: 20px;
		transform: scale(0.6);
		@media (max-width: @screen-xs-max) {
			left: 0;
		}
	}
	.swiper-button-next {
		right: -50px;
		margin-top: 20px;
		transform: scale(0.6);
		@media (max-width: @screen-xs-max) {
			right: 0;
		}
	}
	h2 {
		margin-bottom: 55px;
	}
	.item {
		position: relative;
		height: 160px;
		margin-left: auto;
		margin-right: auto;
	}
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: auto;
		-webkit-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		-o-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
		-webkit-filter: grayscale(1);
		filter: grayscale(1);
		opacity: 0.6;
		margin-left: auto;
		margin-right: auto;
		.transition(all .2s);
		&:hover {
			-webkit-filter: grayscale(0);
			filter: grayscale(0);
			opacity: 1;
		}
	}
	.owl-prev, .owl-next {
		position: absolute;
		top: 50%;
		margin-top: -20px;
		color: darken(@brand-primary, 17.5%);
		font-size: 2.5rem;
		line-height: 1em;
		.transition(all .2s);
		&:hover {
			color: @brand-primary;
		}
	}
	.owl-prev {
		left: -195px;
		@media (max-width: 1359px) {
			left: -85px;
		}
		@media (max-width: @screen-md-max) {
			left: -15px;
		}
		@media (max-width: @screen-xs-max) {
			left: 0;
		}
	}
	.owl-next {
		right: -195px;
		@media (max-width: 1359px) {
			right: -85px;
		}
		@media (max-width: @screen-md-max) {
			right: -15px;
		}
		@media (max-width: @screen-xs-max) {
			right: 0;
		}
	}
}

#footer {
	padding-top: 1px;
	background-color: darken(@brand-primary, 10.5%);
	.newsletter {
		margin-top: -75px;
		.content {
			padding: 28px 45px;
			background-color: #e9eff4;
			@media (max-width: @screen-sm-max) {
				padding-left: 30px;
				padding-right: 30px;
			}
			@media (max-width: @screen-xs-max) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		h2 {
			margin: 0;
			font-size: 1.5rem;
			font-weight: 300;
			line-height: 1.33em;
			max-width: 420px;
		}
		.button {
			position: relative;
			background-color: @brand-primary;
			color: #fff;
			letter-spacing: 1px;
			font-weight: 700;
			line-height: 1.88em;
			text-decoration: none;
			text-transform: none;
			.transition(all .35s);
			@media (max-width: @screen-xs-max) {
				height: 90px;
			}
			span {
				position: absolute;
				top: 50%;
				left: 0;
				padding: 0 190px 0 75px;
				width: 100%;
				-webkit-transform: translate(0,-50%);
				-moz-transform: translate(0,-50%);
				-ms-transform: translate(0,-50%);
				-o-transform: translate(0,-50%);
				transform: translate(0,-50%);
				@media (max-width: @screen-md-max) {
					padding-right: 120px;
				}
				@media (max-width: @screen-sm-max) {
					padding-left: 30px;
					padding-right: 60px;
				}
				@media (max-width: @screen-sm-max) {
					padding-left: 15px;
					padding-right: 30px;
				}
			}
			.fa {
				position: absolute;
				top: 50%;
				right: 85px;
				margin-top: -13px;
				font-size: 1.63rem;
				line-height: 1em;
				@media (max-width: @screen-sm-max) {
					right: 30px;
				}
				@media (max-width: @screen-xs-max) {
					right: 15px;
				}
			}
			&:hover {
				background-color: darken(@brand-primary, 15%);
			}
		}
	}

	.footer-top {
		padding-top: 90px;
		padding-bottom: 40px;
		color: #fff;
		font-size: 0.9rem;
		line-height: normal;
		strong {
			font-weight: 900;
		}
		a {
			color: #fff;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		p {
			margin: 0 0 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.coord {
			line-height: 1.7em;
			@media (max-width: @screen-xs-max) {
				margin-top: 30px;
			}
		}
		.footer-menu {
			@media (max-width: @screen-sm-max) {
				margin-top: 30px;
			}
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					> a, > .nolink {
						display: block;
						padding: 0;
						background-color: transparent;
						color: #fff;
						font-size: 0.8rem;
						line-height: normal;
						text-decoration: underline;
						&:hover {
							text-decoration: none;
						}
					}
					& + li {
						margin-top: 15px;
					}
				}
			}
		}
		.member {
			@media (max-width: @screen-sm-max) {
				margin-top: 30px;
			}
			a {
				font-size: 0.8rem;
			}
			.logos {
				margin: 0 -5px 15px;
				font-size: 0;
				.logo {
					display: inline-block;
					vertical-align: middle;
					margin: 5px;
					height: 100px;
					width: 100px;
					background-color: #fff;
				}
			}
		}
	}

	.footer-bottom {
		color: #fff;
		font-size: 0.8rem;
		line-height: 1.85em;
		.wrapper {
			padding-top: 30px;
			padding-bottom: 30px;
			border-top: 1px solid fade(#e9d7cb, 20%);
		}
		a {
			color: #fff;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		.social-wrapper {
			margin-top: 10px;
			text-align: right;
			font-size: 0;
			@media (max-width: @screen-xs-max) {
				margin-top: 30px;
				text-align: left;
			}
			span, .socials {
				display: inline-block;
				vertical-align: middle;
			}
			span {
				position: relative;
				margin-right: 10px;
				padding-right: 35px;
				font-size: 0.8rem;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					height: 1px;
					width: 20px;
					background-color: fade(#fff, 40%);
				}
			}
		}
		.socials {
			font-size: 0;
			.social {
				display: inline-block;
				vertical-align: middle;
				width: 24px;
				height: 24px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				color: #fff;
				font-size: 1.5rem;
				line-height: 1em;
				text-decoration: none;
				.transition(all .2s);
				& + .social {
					margin-left: 25px;
					@media (max-width: @screen-xs-max) {
						margin-left: 15px;
					}
				}
				&.dm {
					background-image: url(../img/icon-dailymotion.png);
				}
				&:hover {
					color: @brand-red;
					&.dm {
						background-image: url(../img/icon-dailymotion--red.png);
					}
				}
			}
		}
	}
}

#newsletter.modal {
  .captcha {
    .make-md-column(12);
  }
}


.alert-block {
	position: fixed;
	bottom: 0;
	margin: 0;
	width: 100%;
	text-align: center;
	padding: 25px;
	border-radius: none;
	left: 0;
	right: 0;
	z-index: 9999;
	ul {
		li {
			list-style: none;
		}
	}
}

form {
	.alert-block {
		.close {
			top: 25px;
	    right: 25px;
	    left: auto;
	    width: 15px;
	    height: 15px;
			background-image: url('https://d30y9cdsu7xlg0.cloudfront.net/png/52944-200.png');
			background-size: 100%;
			&:hover {
				background-image: url('https://d30y9cdsu7xlg0.cloudfront.net/png/52944-200.png');
				background-size: 100%;
				opacity: 0.6;
			}
		}
	}
}
