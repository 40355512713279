// Home
#leaderboard {
	.slider-wrapper {
		position: relative;
	}
	.swiper-container {
		height: 480px;
	    width: 100%;
	    .swiper-slide {
	    	position: relative;
	    	background-color: @brand-red;
	    	.content {
	    		position: absolute;
	    		z-index: 5;
	    		top: 50%;
	    		left: 0;
	    		width: 100%;
	    		-webkit-transform: translate(0,-50%);
				-moz-transform: translate(0,-50%);
				-ms-transform: translate(0,-50%);
				-o-transform: translate(0,-50%);
				transform: translate(0,-50%);
	    		h2 {
	    			margin: 0 0 30px;
	    			color: #fff;
	    			font-size: 3.44rem;
	    			font-weight: 300;
	    			line-height: normal;
	    			span {
	    				color: #343434;
	    			}
	    			@media (max-width: @screen-xs-max) {
			            font-size: 2.25rem;
			        }
	    		}
	    		a {
	    			color: #fff;
	    			border-color: #fff;
	    			font-size: 1rem;
	    			font-weight: 700;
	    			line-height: 1.25em;
	    			text-transform: none;
	    		}
	    	}
	    	.content-text {
	    		opacity: 0;
				-webkit-transform: translate(-150px,0);
				-moz-transform: translate(-150px,0);
				-ms-transform: translate(-150px,0);
				-o-transform: translate(-150px,0);
				transform: translate(-150px,0);
				.transition(all ease-out .5s);
			}
	    	.bg {
	    		left: 50%;
	    	}
	    }
	    .swiper-slide-active {
			.content-text {
				opacity: 1;
				-webkit-transform: translate(0,0);
				-moz-transform: translate(0,0);
				-ms-transform: translate(0,0);
				-o-transform: translate(0,0);
				transform: translate(0,0);
			}
	    }
	}
	.swiper-pager {
		.clearfix;
		border-bottom: 1px solid #e5e5e5;
		.profil {
			position: relative;
			float: left;
			height: 125px;
			background-color: #fff;

			/* one item */
			&:first-child:nth-last-child(1) {
			    width: 100%;
			}

			/* two items */
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ .profil {
			    width: 50%;
			}

			/* three items */
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ .profil {
			    width: 33.33333333%;
			}

			/* four items */
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ .profil {
			    width: 25%;
			}

			/* five items */
			&:first-child:nth-last-child(5),
			&:first-child:nth-last-child(5) ~ .profil {
			    width: 20%;
			}

			/* six items */
			&:first-child:nth-last-child(6),
			&:first-child:nth-last-child(6) ~ .profil {
			    width: 16.66666667%;
			}

			/* seven items */
			&:first-child:nth-last-child(7),
			&:first-child:nth-last-child(7) ~ .profil {
			    width: 14.28571428%;
			    @media (max-width: @screen-sm-max) {
					width: 25%;
				}
			}

			/* height items */
			&:first-child:nth-last-child(8),
			&:first-child:nth-last-child(8) ~ .profil {
			    width: 12.5%;
			    @media (max-width: @screen-sm-max) {
					width: 25%;
					&:nth-child(n+5) {
						border-top: 1px solid #e5e5e5;
					}
				}
			}

			@media (max-width: @screen-xs-max) {
				border: 0 !important;
				width: 50% !important;
				&:nth-child(n+3) {
					border-top: 1px solid #e5e5e5 !important;
				}
				&:nth-child(even) {
					border-left: 1px solid #e5e5e5 !important;
				}
			}

			& + .profil {
				border-left: 1px solid #e5e5e5;
				@media (max-width: @screen-xs-max) {
					border-left: 0;
				}
			}

			a {
				display: block;
				position: absolute;
				z-index: 1;
				height: 100%;
				width: 100%;
				text-decoration: none;
				-webkit-box-sizing: content-box;
			    -moz-box-sizing: content-box;
			    box-sizing: content-box;
				.transition(all .35s);
				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: @brand-red;
					opacity: 0;
					mix-blend-mode: multiply;
					.transition(all .35s);
				}
				h4, .fa {
					position: absolute;
					z-index: 5;
					left: 0;
					width: 100%;
					text-align: center;
					.transition(all .35s);
				}
				h4 {
					top: 0;
					margin: 0;
					padding: 35px 30px;
					line-height: normal;
			    word-wrap: break-word;
					@media (max-width: 1359px) {
						padding-left: 15px;
						padding-right: 15px;
					}
					@media (max-width: @screen-md-max) {
						padding-left: 5px;
						padding-right: 5px;
					}
				}
				.fa {
					bottom: 40px;
					color: #fff;
					font-size: 1.88rem;
					line-height: 1em;
					opacity: 0;
				}

				.bg {
					opacity: 0;
					.transition(all .35s);
				}
			}

			&:hover {
				a {
					margin-top: -25px;
					padding-top: 25px;
					padding-bottom: 25px;
					box-shadow: 0px 8px 23px 0px rgba(0, 0, 0, 0.4);
					&:after, .bg, .fa {
						opacity: 1;
					}
					h4 {
						color: #fff;
						font-size: 1.25rem;
					}
				}
			}
		}

	}
}

#eshop-products {
	padding-top: 50px;
	padding-bottom: 50px;

	h2 {
		margin-bottom: 25px;
	}

	.products-carousel {
		position: relative;
		margin-bottom: 15px;
	}

	.custom-navigation {
		@media (max-width: @screen-md-max) {
			display: flex;
		}
	}

	.swiper-button-next, .swiper-button-prev {
		position: absolute;
		z-index: 10;
		top: 50%;
		margin-top: -12px;
		width: 14px;
		height: 23px;
		background-position: center;
		background-repeat: no-repeat;
		-moz-background-size: 14px 23px;
		-webkit-background-size: 14px 23px;
		background-size: 14px 23px;
		cursor: pointer;
		@media (max-width: @screen-md-max) {
			position: relative;
			top: auto;
			margin-top: 0;
		}
	}
	.swiper-button-prev {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20320%20512'%3E%3Cpath%20d%3D'M34.52%2C239.03L228.87%2C44.69c9.37-9.37%2C24.57-9.37%2C33.94%2C0l22.67%2C22.67c9.36%2C9.36%2C9.37%2C24.52.04%2C33.9L131.49%2C256l154.02%2C154.75c9.34%2C9.38%2C9.32%2C24.54-.04%2C33.9l-22.67%2C22.67c-9.37%2C9.37-24.57%2C9.37-33.94%2C0L34.52%2C272.97c-9.37-9.37-9.37-24.57%2C0-33.94z'%20fill%3D'%23001c3b'%2F%3E%3C%2Fsvg%3E");
		left: -50px;
		right: auto;
		@media (max-width: 1280px) {
			left: -20px;
		}
		@media (max-width: @screen-md-max) {
			left: auto;
		}
	}
	.swiper-button-next {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20320%20512'%3E%3Cpath%20d%3D'M285.476%2C272.971L91.132%2C467.314c-9.373%2C9.373-24.569%2C9.373-33.941%2C0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505%2C256%2C34.484%2C101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373%2C24.569-9.373%2C33.941%2C0L285.475%2C239.03c9.373%2C9.372%2C9.373%2C24.568.001%2C33.941z'%20fill%3D'%23001c3b'%2F%3E%3C%2Fsvg%3E");
		right: -50px;
		left: auto;
		@media (max-width: 1280px) {
			right: -20px;
		}
		@media (max-width: @screen-md-max) {
			margin-left: 30px;
			right: auto;
		}
	}

	.more {
		text-align: right;
	}
}

#news {
	padding-top: 65px;
	padding-bottom: 75px;
	background-color: #f1efe9;
	.actus, .events {
		.subtitle {
			margin: 0 0 20px;
		}
	}
	.actus {
		padding-right: 20px;
		@media (max-width: @screen-md-max) {
			padding-right: 15px;
		}
	}
	.events {
		padding-left: 20px;
		@media (max-width: @screen-md-max) {
			padding-left: 15px;
		}
		@media (max-width: @screen-sm-max) {
			margin-top: 60px;
		}
		.subtitle {
			background-color: lighten(@brand-primary, 18.5%);
		}
		.link-block {
			margin-top: 35px;
			border-color: lighten(@brand-primary, 18.5%);
			color: lighten(@brand-primary, 18.5%);
			&:hover {
				border-color: transparent;
			}
		}
	}
}

#about {
	position: relative;
	.about {
		position: static;
		@media (max-width: @screen-xs-max) {
			position: relative;
		}
    @media (min-width: @screen-sm-min) {
      display: flex;
      flex-direction: column;
    }
		.content {
			position: relative;
			z-index: 5;
			padding: 80px 0;
			max-width: 500px;
			h1 {
				margin: 0 0 30px;
				color: @brand-primary;
				font-size: 24px;
			}
			h2,h3 {
				font-weight: 300;
			}
		}
		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			right: 50%;
			bottom: 0;
			left: 0;
			background-color: #f8f8f8;
			@media (max-width: @screen-xs-max) {
				right: 0;
			}

		}
		.bg-about {
      position: relative;
      z-index: 5;
      margin-left: -15px;
      margin-right: -15px;
			mix-blend-mode: multiply;

      @media (min-width: @screen-sm-min) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: auto;
        margin-left: 0;
      }

      .btn-video {
        display: block;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          background-color: @brand-primary;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffffff' d='M14.25,6.69c.73,.43,.97,1.37,.53,2.1-.13,.22-.31,.4-.53,.52L3.34,15.78c-.73,.43-1.68,.2-2.12-.52-.14-.24-.22-.51-.22-.79V1.53C1,.68,1.69,0,2.55,0c.28,0,.55,.08,.79,.22L14.25,6.69Z'/%3e%3c/svg%3e");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 10px 10px;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
          transform: translate(-50%, -50%);
          transition: all .2s ease-in;
        }

        &:hover {
          &::after {
            width: 64px;
            height: 64px;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
          }
        }
      }

      img {
        display: block;
        width: 100%;
        max-width: none;
        height: auto;

        @media (min-width: @screen-sm-min) {
          width: 50vw;
        }
      }
		}
	}
	.services {
		padding-top: 190px;
		padding-bottom: 35px;
		padding-left: 30px;
		@media (max-width: @screen-md-max) {
			padding-top: 80px;
		}
		@media (max-width: @screen-sm-max) {
			padding-left: 15px;
		}
	}
}
#videoModal {
  .modal-dialog {
    margin: 110px auto 0;
    width: 1170px;
    max-width: 100%;
  }

  .modal-content {
    border: 0;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    > *:not(.close) {
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    }
  }

  .modal-body {
    padding: 15px;

    .iframe-embed {
      margin-bottom: 0;
    }
  }
}

#atouts {
	position: relative;
	overflow: hidden;
	.atouts {
		padding-top: 65px;
		padding-bottom: 50px;
	}
	.cta {
		position: static;
		@media (max-width: @screen-xs-max) {
			position: relative;
		}
		.content {
			position: relative;
			z-index:  5;
			padding: 100px 0;
			padding-left: 45px;
			max-width: 330px;
			color: #fff;
			font-size: 1.13rem;
			line-height: 1.67em;
			@media (max-width: @screen-sm-max) {
				padding-left: 0;
			}
			p {
				margin: 0 0 40px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.link-block {
				color: @brand-lightblue;
				border-color: @brand-lightblue;
				&:hover {
					border-color: transparent;
				}
			}
			.img-cta {
				position: absolute;
				top: 80px;
				left: 100%;
				margin-left: 135px;
				max-width: none;
				box-shadow: 0px 4px 0px 0px rgba(148, 148, 148, 1), 4px 21px 30px 0px rgba(77, 66, 56, 0.29);
				@media (max-width: 1359px) {
					margin-left: 60px;
				}
				@media (max-width: @screen-md-max) {
					margin-left: 30px;
				}
				@media (max-width: @screen-sm-max) {
					position: relative;
					top: auto;
					left: auto;
					margin: 30px 0 0;
				}
			}
		}
		&:after {
			content: '';
			.bg;
			left: 50%;
			background-image: url(../img/bg-cta.jpg);
			@media (max-width: @screen-xs-max) {
				left: 0;
			}
		}
	}
}

#abo {
	padding: 45px 0;
	background-color: #f1efe9;
	font-size: 0;
	.content, .cta {
		display: inline-block;
		vertical-align: middle;
		float: none;
	}
	.content {
		font-size: 1rem;
	}
	h3 {
		margin: 0;
		color: darken(@brand-primary, 14.5%);
		font-weight: 300;
		line-height: 1.33em;
		strong {
			color: @brand-primary;
			font-weight: 400;
		}
	}
	.cta {
		text-align: right;
		@media (max-width: @screen-xs-max) {
			margin-top: 30px;
			text-align: left;
		}
	}
	.btn {
		padding: 20px 30px;
		font-size: 1rem;
		font-weight: 700;
		letter-spacing: 1px;
	}
}
