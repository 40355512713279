// Header
#header {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  .transition(all 0.2s);
  .preheader {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: darken(@brand-primary, 15%);
    color: #fff;

    @media (min-width: @screen-sm-min) {
      .preheader_inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      .preheader_text {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;
      }
      .preheader_cta {
        flex: 0 0 auto;
        width: auto;
        padding-left: 20px;
      }
    }

    .preheader_cta {
      .btn {
        padding: 10px 10px;
      }
    }
  }
  .topbar {
    .clearfix;
    padding: 15px;
    background-color: @brand-primary;
    .left,
    .share,
    .search,
    .languages,
    .trigger-menu {
      float: left;
    }
    .right {
      float: right;
    }
  }
  .mainbar {
    padding: 15px 0;
    background-color: #fff;
    @media (max-width: @screen-sm-max) {
      font-size: 0;
      text-align: center;
    }
  }
  .trigger-share,
  .addthis_toolbox a,
  .trigger-search,
  .languages .label,
  .languages a,
  .topNav a,
  .topNav .nolink {
    display: block;
    padding: 2px 0;
    border-bottom: 1px solid transparent;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.67em;
    text-decoration: none;
    text-transform: uppercase;
    .transition(all 0.2s);
    &:hover,
    &.active {
      border-color: #fff;
    }
  }
  .share,
  .languages {
    ul {
      display: none;
      position: absolute;
      z-index: 5;
      top: 100%;
      left: 0;
      margin: 0;
      padding: 10px 15px;
      list-style: none;
      background-color: #fff;
      border: 1px solid @brand-primary;
      a {
        color: @brand-primary;
        white-space: nowrap;
        &:hover {
          border-color: @brand-primary;
        }
      }
    }
  }
  .share {
    position: relative;
    .trigger-share,
    .addthis_toolbox a {
      font-size: 0;
      letter-spacing: 1px;
      .fa,
      span {
        display: inline-block;
        vertical-align: middle;
      }
      .fa {
        margin-right: 6px;
        font-size: 1.06rem;
        line-height: 1.18em;
      }
      span {
        font-size: 0.75rem;
      }
    }
  }
  .search {
    position: relative;
    @media (max-width: @screen-xs-max) {
      position: static;
    }
    .searchBox {
      display: none;
      position: absolute;
      top: 100%;
      right: -15px;
      width: 300px;
      @media (max-width: @screen-xs-max) {
        top: 55px;
        left: 0;
        right: 0;
        width: auto;
      }
      form {
        > div {
          font-size: 0;
          .form-group {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0 !important;
            &.form-type-textfield {
              width: calc(~"100% - 50px");
            }
            &.form-actions {
              .btn {
                margin: 0;
                padding: 0;
                height: 50px;
                width: 50px;
                border-radius: 0;
                line-height: 50px;
                background-image: url(../img/icon-search--white.png);
                background-position: center;
                background-repeat: no-repeat;
                text-indent: -9999px;
              }
            }
          }
        }
      }
      ::-webkit-input-placeholder {
        color: @brand-primary;
      }
      ::-moz-placeholder {
        color: @brand-primary;
      }
      :-ms-input-placeholder {
        color: @brand-primary;
      }
      :-moz-placeholder {
        color: @brand-primary;
      }
    }
  }
  .languages {
    position: relative;
    margin-left: 35px;
    .label {
      cursor: pointer;
      .fa {
        margin-left: 4px;
      }
    }
    ul {
      left: auto;
      right: 0;
    }
  }
  .topNav {
    position: absolute;
    top: 75px;
    right: 120px;
    @media (max-width: @screen-sm-max) {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 60px;
    }
    > ul {
      float: none;
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        float: left;
        @media (max-width: @screen-sm-max) {
          float: none;
        }
        > a,
        > .nolink {
          background-color: transparent;
          letter-spacing: 1px;
          @media (max-width: @screen-sm-max) {
            padding: 10px 15px;
          }
        }
        & + li {
          margin-left: 25px;
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }
        &.eshop {
          margin-top: -8px;
          @media (max-width: @screen-sm-max) {
            margin-top: 0;
          }
          a {
            padding: 10px 15px;
            border: 0;
            background-color: #fff;
            color: @brand-primary;
            font-weight: 900;
            letter-spacing: 1.5px;
            text-align: center;
            &:before {
              content: "";
              display: inline-block;
              vertical-align: middle;
              margin-top: -2px;
              margin-right: 10px;
              width: 16px;
              height: 16px;
              background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 512"%3E%3Cpath fill="%23005494" d="M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-33.5 53.5-3.8 127.9 58.8 136.4 4.5.6 9.1.9 13.7.9 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18.1 20.1 44.3 33.1 73.8 33.1 4.7 0 9.2-.3 13.7-.9 62.8-8.4 92.6-82.8 59-136.4zM529.5 288c-10 0-19.9-1.5-29.5-3.8V384H116v-99.8c-9.6 2.2-19.5 3.8-29.5 3.8-6 0-12.1-.4-18-1.2-5.6-.8-11.1-2.1-16.4-3.6V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32V283.2c-5.4 1.6-10.8 2.9-16.4 3.6-6.1.8-12.1 1.2-18.2 1.2z"/%3E%3C/svg%3E');
              background-position: center;
              background-repeat: no-repeat;
              background-size: 16px auto;
              .transition(all 0.2s);
            }
          }
          &:hover {
            a {
              background-color: darken(@brand-primary, 7.5%);
              color: #fff;
              &:before {
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 616 512"%3E%3Cpath fill="%23ffffff" d="M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-33.5 53.5-3.8 127.9 58.8 136.4 4.5.6 9.1.9 13.7.9 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18.1 20.1 44.3 33.1 73.8 33.1 4.7 0 9.2-.3 13.7-.9 62.8-8.4 92.6-82.8 59-136.4zM529.5 288c-10 0-19.9-1.5-29.5-3.8V384H116v-99.8c-9.6 2.2-19.5 3.8-29.5 3.8-6 0-12.1-.4-18-1.2-5.6-.8-11.1-2.1-16.4-3.6V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32V283.2c-5.4 1.6-10.8 2.9-16.4 3.6-6.1.8-12.1 1.2-18.2 1.2z"/%3E%3C/svg%3E');
              }
            }
          }
        }
        &.user,
        &.logout {
          a {
            opacity: 0.8;
            text-transform: none;
          }
        }
        &.user {
          margin-left: 30px;
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }
        &.logout {
          margin-left: 10px;
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }
        &.cart {
          a {
            position: relative;
            letter-spacing: 2px;
            line-height: 1em;
            @media (max-width: @screen-sm-max) {
              display: inline-block;
            }
            &:before {
              content: "\f07a";
              font-family: FontAwesome;
              margin-right: 10px;
              font-size: 1.25rem;
              line-height: 1em;
            }
            span {
              position: absolute;
              bottom: -5px;
              left: -8px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 2px solid @brand-primary;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              background-color: #fff;
              color: @brand-primary;
              font-size: 0.75rem;
              @media (max-width: @screen-sm-max) {
                bottom: 3px;
                left: 7px;
              }
            }
          }
        }
      }
    }
  }

  .logo {
    float: left;
    max-width: 170px;
    @media (max-width: @screen-sm-max) {
      display: inline-block;
    }
  }
  .mainNav {
    float: right;
    margin-top: 25px;
    @media (max-width: @screen-sm-max) {
      float: none;
      margin-top: 0;
    }
    > ul {
      float: none;
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        float: left;
        @media (max-width: @screen-sm-max) {
          float: none;
        }
        > a,
        > .nolink {
          padding: 0;
          background-color: transparent;
          color: darken(@brand-primary, 7.5%);
          font-size: 0.81rem;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          .transition(all 0.2s);
          @media (max-width: @screen-sm-max) {
            padding: 15px;
            color: #fff;
            br {
              display: none;
            }
          }
        }
        &:hover,
        &.active {
          > a,
          > .nolink {
            color: lighten(@brand-primary, 5%);
            @media (max-width: @screen-sm-max) {
              color: #fff;
              text-decoration: underline;
            }
          }
        }
        & + li {
          margin-left: 35px;
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }
      }
    }
  }
  &.scrolled {
    top: -115px;
  }
  &.extranet {
    .mainbar {
      text-align: center;
    }
    .logo {
      display: inline-block;
      vertical-align: middle;
      float: none;
    }
  }
}

.trigger-menu {
  position: relative;
  z-index: 1;
  margin-left: 30px;
  padding: 5px 0 4px;
  svg {
    display: block;
    rect {
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -ms-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
      .transition(all 0.2s);
    }
  }
  &.open {
    svg {
      rect {
        &:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          x: -3px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          x: -3px;
        }
      }
    }
  }
}

@media (max-width: @screen-sm-max) {
  nav#nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 60px 0;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-color: fade(@brand-primary, 100%);
    overflow-x: auto;
    overflow-y: scroll;
    .transition(all 0.35s);
  }
}

body.open-menu {
  @media (max-width: @screen-sm-max) {
    height: 100%;
    overflow: hidden;
    nav#nav {
      visibility: visible;
      opacity: 1;
    }
  }
}

.cta-contact,
.cta-connexion {
  position: fixed;
  z-index: 160;
  bottom: 370px;
  right: 0;
  max-width: 250px;
  white-space: nowrap !important;
  .transition(all 0.35s);
  @media (max-width: @screen-xs-max) {
    bottom: 30px;
  }
  span {
    .transition(all 0.35s);
  }
  &.scrolled {
    max-width: 75px;
    span {
      opacity: 0;
    }
    &:hover {
      max-width: 250px;
      span {
        opacity: 1;
      }
    }
  }
}
.cta-connexion {
  bottom: auto;
  top: 360px;
  background-color: @brand-red !important;
  color: #fff !important;
  font-size: 0.75rem !important;
  &:hover {
    background-color: darken(@brand-red, 15%) !important;
  }
  @media (max-width: @screen-xs-max) {
    top: auto;
    bottom: 105px;
    max-width: 75px;
    span {
      opacity: 0;
    }
  }
}
body.not-front {
  .cta-contact {
    bottom: auto;
    top: 285px;
    @media (max-width: @screen-xs-max) {
      top: auto;
      bottom: 30px;
    }
  }
}

.breadcrumb {
  margin: 0 0 -10px;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  list-style: none;
  > li {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 1.7em;
    a {
      color: @brand-lighterblue;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    & + li {
      &:before {
        content: "/";
        padding: 0 5px;
        color: #fff;
      }
    }
  }
  > .active {
    color: #fff;
  }
}

#banner {
  position: relative;
  height: 240px;
  background-color: @brand-darkblue;
  @media (max-width: @screen-xs-max) {
    height: 300px;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(
      60deg,
      rgba(0, 84, 148, 1) 0%,
      rgba(0, 84, 148, 0) 100%
    );
    background: -webkit-linear-gradient(
      60deg,
      rgba(0, 84, 148, 1) 0%,
      rgba(0, 84, 148, 0) 100%
    );
    background: linear-gradient(
      60deg,
      rgba(0, 84, 148, 1) 0%,
      rgba(0, 84, 148, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005494', endColorstr='#00005494',GradientType=1 );
  }
  .content {
    position: relative;
    z-index: 5;
    padding-top: 45px;
    @media (max-width: @screen-xs-max) {
      padding-top: 30px;
    }
    h1 {
      max-width: 630px;
      font-size: 2.3em;
      line-height: 1.3em;
      margin-top: 15px;
    }
  }
  &.banner-service {
    height: 370px;
    &.cat5 {
      background-color: @brand-red;
      &:after {
        background: -moz-linear-gradient(
          60deg,
          rgba(228, 49, 43, 1) 0%,
          rgba(228, 49, 43, 0) 100%
        );
        background: -webkit-linear-gradient(
          60deg,
          rgba(228, 49, 43, 1) 0%,
          rgba(228, 49, 43, 0) 100%
        );
        background: linear-gradient(
          60deg,
          rgba(228, 49, 43, 1) 0%,
          rgba(228, 49, 43, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4312b', endColorstr='#00e4312b',GradientType=1 );
      }
      .breadcrumb {
        > li {
          a {
            color: tint(@brand-red, 52.5%);
          }
        }
      }
    }
    &.cat6 {
      background-color: @brand-orange;
      &:after {
        background: -moz-linear-gradient(
          60deg,
          rgba(247, 122, 0, 1) 0%,
          rgba(2247, 122, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
          60deg,
          rgba(247, 122, 0, 1) 0%,
          rgba(247, 122, 0, 0) 100%
        );
        background: linear-gradient(
          60deg,
          rgba(247, 122, 0, 1) 0%,
          rgba(247, 122, 0, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f77a00', endColorstr='#00f77a00',GradientType=1 );
      }
      .breadcrumb {
        > li {
          a {
            color: tint(@brand-orange, 52.5%);
          }
        }
      }
    }
    &.cat4 {
      background-color: @brand-yellow;
      &:after {
        background: -moz-linear-gradient(
          60deg,
          rgba(200, 168, 60, 1) 0%,
          rgba(200, 168, 60, 0) 100%
        );
        background: -webkit-linear-gradient(
          60deg,
          rgba(200, 168, 60, 1) 0%,
          rgba(200, 168, 60, 0) 100%
        );
        background: linear-gradient(
          60deg,
          rgba(200, 168, 60, 1) 0%,
          rgba(200, 168, 60, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c8a83c', endColorstr='#00c8a83c',GradientType=1 );
      }
      .breadcrumb {
        > li {
          a {
            color: tint(@brand-yellow, 52.5%);
          }
        }
      }
    }
    &.cat7 {
      background-color: @brand-blue;
      &:after {
        background: -moz-linear-gradient(
          60deg,
          rgba(0, 63, 110, 1) 0%,
          rgba(0, 63, 110, 0) 100%
        );
        background: -webkit-linear-gradient(
          60deg,
          rgba(0, 63, 110, 1) 0%,
          rgba(0, 63, 110, 0) 100%
        );
        background: linear-gradient(
          60deg,
          rgba(0, 63, 110, 1) 0%,
          rgba(0, 63, 110, 0) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003f6e', endColorstr='#00003f6e',GradientType=1 );
      }
      .breadcrumb {
        > li {
          a {
            color: tint(@brand-blue, 52.5%);
          }
        }
      }
    }

    .content {
      padding-top: 95px;
    }
    .bg {
      opacity: 0.77;
    }
    .breadcrumb {
      margin: 0 0 5px;
    }
  }
}

@media (max-width: @screen-sm-max) {
  body:not(.cke_editable) {
    padding-top: 158px !important;
  }
  header {
    .logo {
      float: left;
    }
    .trigger-menu {
      position: fixed;
      top: 88px;
      padding: 10px;
      background: @brand-blue;
      transition: all 0.2s ease-in-out;
    }
    &.scrolled {
      .trigger-menu {
        top: 33px;
      }
    }
  }
  .cta-contact {
    display: none !important;
  }
}
