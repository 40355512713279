@-webkit-keyframes easeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
	}
	
	60% {
		opacity: 1;
		-webkit-transform: translateX(30px);
	}
	
	100% {
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes easeInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-2000px);
	}
	
	60% {
		opacity: 1;
		-moz-transform: translateX(30px);
	}
		
	100% {
		-moz-transform: translateX(0);
	}
}

@-ms-keyframes easeInLeft {
	0% {
		opacity: 0;
		-ms-transform: translateX(-2000px);
	}
	
	60% {
		opacity: 1;
		-ms-transform: translateX(30px);
	}
	
	100% {
		-ms-transform: translateX(0);
	}
}

@-o-keyframes easeInLeft {
	0% {
		opacity: 0;
		-o-transform: translateX(-2000px);
	}
	
	60% {
		opacity: 1;
		-o-transform: translateX(30px);
	}
		
	100% {
		-o-transform: translateX(0);
	}
}

@keyframes easeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-2000px);
	}
	
	60% {
		opacity: 1;
		transform: translateX(30px);
	}
	
	100% {
		transform: translateX(0);
	}
}

.easeInLeft {
  .animation-name(easeInLeft);
}