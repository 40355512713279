// Profil
body.node-type-profil {
  .cta-contact {
    top: auto;
    bottom: 340px;
    @media (max-width: @screen-xs-max) {
        bottom: 30px;
    }
  }
}
#profil-leaderboard {
  position: relative;
  .title {
    position: absolute;
    z-index: 110;
    top: 50%;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 1.15rem;
    -webkit-transform: translate(0,-50%);
    -moz-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    -o-transform: translate(0,-50%);
    transform: translate(0,-50%);
    h1 {
      margin: 0 0 35px;
    }
    p {
      max-width: 480px;
    }
  }
  .cycle-slideshow {
    height: 500px;
    width: 100%;
    .cycle-pager {
      position: absolute;
      z-index: 120;
      bottom: 175px;
      left: 60px;
      @media (max-width: @screen-md-max) {
        bottom: 60px;
      }
      @media (max-width: @screen-xs-max) {
        bottom: 30px;
        left: 15px;
      }
      span {
        display: block;
        height: 10px;
        width: 10px;
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        text-indent: -9999px;
        .transition(all .2s);
        @media (max-width: @screen-md-max) {
          float: left;
        }
        & + span {
          margin-top: 20px;
          @media (max-width: @screen-md-max) {
            margin-top: 0;
            margin-left: 20px;
          }
        }
        &:hover, &.cycle-pager-active {
          background-color: #fff;
        }
      }
    }
    .slide {
      height: 500px;
      width: 100%;
      background-color: #000;
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(60deg,  rgba(0,84,148,1) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(60deg,  rgba(0,84,148,1) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(60deg,  rgba(0,84,148,1) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005494', endColorstr='#00000000',GradientType=1 );
      }
    }
  }
}

#profil-services {
  padding-top: 55px;
  padding-bottom: 80px;
  h2 {
    margin: 0 0 45px;
  }
  .service {
    display: block;
    position: relative;
    padding: 175px 40px 40px;
    min-height: 320px;
    background-position: left 40px top 60px;
    background-repeat: no-repeat;
    color: #3c3c3c;
    font-size: 0.9rem;
    line-height: normal;
    text-decoration: none !important;
    .transition(all .35s);
    @media (max-width: @screen-xs-max) {
      padding: 120px 15px 40px;
      min-height: 0;
      background-position: left 15px top 15px;
    }
    h3 {
      margin: 0 0 5px;
      color: #414141;
      font-family: @ff-body;
      font-size: 0.9rem;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      .transition(all .35s);
    }
    p {
      margin: 0;
    }
    .fa {
      position: absolute;
      bottom: 15px;
      left: 40px;
      color: #fff;
      font-size: 1.55rem;
      line-height: 1em;
      opacity: 0;
      -webkit-transform: translate(0,10px);
      -moz-transform: translate(0,10px);
      -ms-transform: translate(0,10px);
      -o-transform: translate(0,10px);
      transform: translate(0,10px);
      .transition(all .35s);
      @media (max-width: @screen-xs-max) {
        left: 15px;
      }
    }
    &:hover {
      color: #fff;
      h3 {
        color: #fff;
      }
      .fa {
        opacity: 1;
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
      }
    }
    &.service20, &.service179, &.service260 {
      background-image: url(../img/icon-service20.png);
      &:hover {
        background-color: @brand-red;
        background-image: url(../img/icon-service20--white.png);
      }
    }
    &.service16, &.service631, &.service589 {
      background-image: url(../img/icon-service16.png);
      &:hover {
        background-color: @brand-orange;
        background-image: url(../img/icon-service16--white.png);
      }
    }
    &.service17, &.service568, &.service584 {
      background-image: url(../img/icon-service17.png);
      &:hover {
        background-color: @brand-yellow;
        background-image: url(../img/icon-service17--white.png);
      }
    }
    &.service19, &.service482, &.service540, &.service540   {
      background-image: url(../img/icon-service19.png);
      &:hover {
        background-color: @brand-blue;
        background-image: url(../img/icon-service19--white.png);
      }
    }
  }
}

#profil-content {
  padding-top: 50px;
  padding-bottom: 90px;
  background-color: #f9f8f3;
}
