// Shop
body.page-eshop {
  #banner {
    .content {
      h1 {
        max-width: 800px;
        @media (max-width: @screen-xs-max) {
          font-size: 1.875rem;
        }
      }
    }
  }
  #products-shop {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .item--event {
    img {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
.item--product {
  .content {
    height: 200px;
  }
  h3 {
    height: 70px;
  }
  p {
    height: 62px;
    margin-bottom: 0;
  }
}
.node-event {
  #subscribe-form {
    p {
      text-align: center;
      a {
        margin: 20px 0;
      }
    }
  }
}
.product-price {
  .content {
    padding: 30px 15px;
    background-color: @brand-primary;
    color: #fff;
    text-align: center;
    h3 {
      margin: 0;
      color: #fff;
      line-height: 1.33em;
    }
    h4 {
      margin-top: 30px;
      color: #fff;
    }
  }
}

#subscribe-form {
  .stripe-errors {
    margin: 15px;
    padding: 15px;
  }
}
.subscription-links {
  padding: 30px;
  text-align: center;
  background: @brand-primary;
  margin: 10px 0;
  a {
    display: inline-block;
    padding: 10px 20px;
    background: #fff;
    color: @brand-primary;
    font-weight: bold;
    @media (max-width: @screen-xs-max) {
      display: block;
    }
  }
  h2 {
    color: #fff;
    font-size: 1.5rem;
  }
}
#new-shop {
  margin: 20px 0 20px 0;
}
.node-produit {
  .product-info {
    p {
      margin: 0 0 10px;
    }
    ul {
      margin-bottom: 10px;
      padding-left: 0;
      li {
        margin: 0 0 0 0;
        color: @gray-light;
        list-style: none;
        &:before {
          content: '\f178';
          font-family: FontAwesome;
          margin-right: 10px;
          font-size: .9rem;
          color: @gray-lighter;
        }
      }
    }
  }
  .content {
    margin-top: 20px;
  }
  #subscribe-form {
    button {
      float: right;
      margin-right: 15px;
      &:after {
        display: none;
      }
    }
  }
  p.special {
    text-align: center;
    a {
      margin: 20px 0;
    }
  }
}
#edit-back {
  &:after {
    display: none;
  }
  &:before {
    content: '\f060';
    font-family: FontAwesome;
    margin-right: 20px;
  }
}
#subscribe-form {
  button {
    float: right;
    margin-right: 15px;
  }
}
.page-cart, .page-order {
  .cart-product {
    margin-top: 20px;
  }
  .no-result {
    margin: 50px 15px;
  }
  table {
    border: 0;
    margin: 20px 0;
    tr {
      th {
        padding-left: .5rem;
        padding-right: .5rem;
        font-size: .9rem;
        text-transform: none;
      }
      td {
        padding-left: 1rem;
        padding-right: 1rem;
        border-bottom: 1px solid #fff;
        &:last-child {
          text-align: right;
        }
        &.qte {
          text-align: center;
          a {
            display: inline-block;
            margin: 0 5px;
            &.delete {
              padding: 3px 5px;
              background: @brand-red;
              color: #fff;
              font-size: .8rem;
            }
          }
        }
      }
    }
    tbody {
      &:last-child {
        tr {
          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.btn-back, .back-box button.btn.btn-default {
  background: @gray-lighter;
  color: @gray;
  &:after {
    display: none;
  }
}
.cart-cta {
  margin: 30px 0;
  padding: 30px;
  background: @brand-primary;
  color: #fff;
  text-align: center;
  a {
    color: #fff;
  }
}
.node-type-produit {
  #banner {
    height: auto;
    .content {
      padding-bottom: 45px;
    }
  }
  .actions {
    text-align: center;
  }
  .node-details {
    padding-bottom: 0;
  }
  h3.division-title {
    margin-bottom: 10px;
    font-size: 1.15em;
  }
  table.table-variants {
    tr {
      td, th {
        font-size: .85rem;
      }
    }
  }
  #subscribe-form {
    fieldset {
      margin-top: 0;
      .panel-title {
        margin-left: 15px;
      }
    }
  }
}
.formules {
  a.btn {
    font-size: .7em;
    width: 110px;
    height: 70px;
  }
  table {
    tr {
      td {
        text-align: center;
        &:nth-child(1) {
          text-align: left;
        }
      }
    }
  }
}
