// Webform
body.node-type-webform {
  #main {
    padding-top: 70px;
    padding-bottom: 95px;
    form {
      margin-left: -15px;
      margin-right: -15px;
      &:not(:first-child) {
        margin-top: 60px;
      }
      .form-inline > div {
        margin-left: -15px !important;
        margin-right: -15px !important;
        .checkbox, .radio {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
      .form-actions {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}