// Formulaire
.form-group {
  margin: 0 0 10px !important;
}
.form-control {
    height: 50px;
    width: 100%;
    padding: 0 15px;
    background-color: #f3f3f3;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.form-control, output, label {
	display: block;
  color: @brand-primary; 
  font-size: 1rem; 
  font-weight: 400;
  line-height: 1.9em;
}
label {
  margin: 0;
}
::-webkit-input-placeholder {
  color: @brand-primary;
}
::-moz-placeholder {
  color: @brand-primary;
}
:-ms-input-placeholder {
  color: @brand-primary;
}
:-moz-placeholder {
  color: @brand-primary;
}

.checkbox, .radio {
  label {
    display: block;
    position: relative;
    margin: 0 0 10px !important;
    padding: 0 0 0 40px !important;
    min-height: 0;
  }
}
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox],
.radio input[type=radio], .radio-inline input[type=radio] {
  position: absolute !important;
  top: 3px;
  left: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  background-color: #f3f3f3;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    width: 10px;
    height: 10px;
    background-color: @brand-primary;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    .transition(all .2s);
  }
  &:checked {
    &:after {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
}
.radio input[type=radio], .radio-inline input[type=radio] {
  border-radius: 50%;
  &:after {
    border-radius: 50%;
  }
}

.form-inline > .form-group {
  margin-left: -15px !important;
  margin-right: -15px !important;
  > label, .checkbox, .radio {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.form-submit {
  margin-top: 30px;
  padding: 14px 20px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: .5px;
  line-height: normal;
  &:after {
    content: '\f061';
    margin-left: 20px;
    font-family: 'FontAwesome';
    font-size: 1rem;
    font-weight: 400;
  }
}

.form-managed-file {
  font-size: 0;
  .form-file, .input-group-btn {
    display: inline-block;
    vertical-align: middle;
  }
  .form-file {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px 0 0 5px;
    width: calc(~"100% - 120px");
  }
  .input-group-btn {
    .btn {
      margin: 0;
      padding: 0;
      border-radius: 0 5px 5px 0;
      width: 120px;
      height: 50px;
      line-height: 50px;
      &:after {
        display: none;
      }
    }
  }
}

fieldset {
  margin: 45px 0;
  border: 5px solid #e6e6e6;
  padding: 35px 15px 40px;
  fieldset {
    border: 0;
    padding: 0;
  }
  legend {
    display: block !important;
    margin: 0 0 30px !important;
    border: 0;
    padding: 0;
    color: @brand-primary;
    font-size: 1.5rem;
    font-family: @ff-headings;
    line-height: 1.15em !important;
  }
}

#edit-stripe {
  .form-text, .form-select {
    .form-control;
  }
}

.form-item-submitted-send-a-mail-yourself {
  display: inline-block;
  padding-left: 15px;
}
