@-webkit-keyframes scaleInBig {
	0% {
		opacity: 0;
		-webkit-transform:scale(100)
	}
	
	50% {
		opacity: .2;
		-webkit-transform: scale(.50);
	}
	
	70% {
		opacity: .9;
		-webkit-transform: scale(1.1);
	}
	
	100% {
		opacity:1;
	}
}

@-moz-keyframes scaleInBig {
	0% {
		opacity: 0;
		-moz-transform:scale(100)
	}
	
	50% {
		opacity: .2;
		-moz-transform: scale(.50);
	}
	
	70% {
		opacity: .9;
		-moz-transform: scale(1.1);
	}
	
	100% {
		opacity:1;
	}
}

@-ms-keyframes scaleInBig {
	0% {
		opacity: 0;
		-ms-transform:scale(100)
	}
	
	50% {
		opacity: .2;
		-ms-transform: scale(.50);
	}
	
	70% {
		opacity: .9;
		-ms-transform: scale(1.1);
	}
	
	100% {
		opacity:1;
	}
}

@-o-keyframes scaleInBig {
	0% {
		opacity: 0;
		-o-transform:scale(100)
	}
	
	50% {
		opacity: .2;
		-o-transform: scale(.50);
	}
	
	70% {
		opacity: .9;
		-o-transform: scale(1.1);
	}
	
	100% {
		opacity:1;
	}
}

@keyframes scaleInBig {
	0% {
		opacity: 0;
		transform:scale(100)
	}
	
	50% {
		opacity: .2;
		transform: scale(.50);
	}
	
	70% {
		opacity: .9;
		transform: scale(1.1);
	}
	
	100% {
		opacity:1;
	}
}

.scaleInBig {
	.animation-name(scaleInBig);
}