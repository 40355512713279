#sliding-popup {
  position: fixed;
  z-index: 1000;
  left: 0;
  padding: 0;
  width: 100%;
  max-width: 600px;
  text-align: left;

  &,
  .eu-cookie-withdraw-banner {
    background: unset;
  }

  label {
    color: unset;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.eu-cookie-compliance-content {
  display: block !important;
  position: relative;
  margin: 0 !important;
  border: 0;
  border-top-right-radius: 1.5rem;
  padding: 2rem 1.5rem;
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100vh;
  background: @brand-primary-light;
  box-shadow: 0 0 0.375rem fade(@brand-primary, 0.4);
  color: #313637;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left !important;
  overflow: auto;

  @media (min-width: @screen-sm-min) {
    padding-left: 2rem;
    padding-right: 2rem;
    box-shadow: 0 0.1875rem 0.375rem fade(#000, 0.15);
    font-size: 0.875rem;
    line-height: 1.2858;
  }
}

.eu-cookie-compliance-message {
  float: none !important;
  margin-bottom: 1.5rem;
  max-width: 440px !important;

  @media (min-width: @screen-sm-min) {
    margin-bottom: 2rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: inherit;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    line-height: 1.2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 1.375rem;

    @media (min-width: @screen-md-min) {
      font-size: 1.75rem;
    }
  }

  h3 {
    font-size: 1.125rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  h6 {
    font-size: 0.75rem;
  }

  p {
    margin-bottom: 0;
    font-size: inherit;
  }
}

.eu-cookie-compliance-categories {
  position: relative;
  margin: 0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;

  .eu-cookie-compliance-categories__inner {
    padding-top: 2rem;
  }

  .eu-cookie-compliance-category {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      padding-left: 1.125rem + 0.5rem;
    }

    input[type="checkbox"] {
      position: relative;
      vertical-align: top;
      float: left;
      margin-top: 0;
      margin-left: (1.125rem + 0.5rem) * -1;
      border: 1px solid fade(#000, 0.25);
      border-radius: 0.25rem;
      width: 1.125rem;
      height: 1.125rem;
      background-color: #fff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      appearance: none;
      print-color-adjust: exact;

      ~ label {
        padding: 0;
      }

      &:focus {
        border-color: mix(#fff, @brand-primary, 50%);
        box-shadow: none;
        outline: 0;
      }

      &:checked {
        border-color: @brand-primary;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23005494' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
        filter: none;
      }

      &[disabled],
      &:disabled {
        ~ label {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    .eu-cookie-compliance-category-description {
      margin: 0.25rem 0 0;
      color: #5f7581;
      font-size: 0.75rem;
      line-height: 1.1667;
    }
  }
}

.eu-cookie-compliance-buttons {
  display: flex;
  flex-wrap: wrap;
  float: none !important;
  margin: -0.375rem;
  max-width: 100% !important;

  button {
    margin: 0.375rem;
  }
}

.eu-cookie-compliance-default-button,
.eu-cookie-compliance-secondary-button,
.eu-cookie-compliance-hide-button,
.eu-cookie-withdraw-button,
.eu-cookie-compliance-save-preferences-button,
.eu-cookie-compliance-personalize-button {
  &:extend(.btn);
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1;
  text-transform: none;
}
.eu-cookie-compliance-default-button,
.eu-cookie-withdraw-button,
.eu-cookie-compliance-save-preferences-button {
  &:extend(.btn-primary);
}
.eu-cookie-compliance-secondary-button,
.eu-cookie-compliance-hide-button,
.eu-cookie-compliance-personalize-button {
  background-color: transparent;
  border: 2px solid @brand-primary;
  color: @brand-primary;

  &:hover {
    background-color: @brand-primary;
    color: #fff;
  }
}
.eu-cookie-compliance-more-button {
  display: inline;
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: bolder;
  }
}
.eu-cookie-withdraw-tab {
  display: block;
  position: absolute;
  top: auto;
  bottom: calc(100% - 2px);
  left: 50%;
  border: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.25rem 0.5rem;
  background: @brand-primary-light;
  font-size: 0.75rem;
  line-height: 1;
  transform: translateX(-50%);
  appearance: none;
  cursor: pointer;
}
.eu-cookie-compliance-close-button {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: transparent;
  color: @brand-primary;
  text-indent: -9999px;
  overflow: hidden;
  appearance: none;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: calc(50% + 1px);
    left: 20%;
    border-top: 2px solid currentColor;
    width: 60%;
    height: 0;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:focus {
    outline: 2px solid currentColor;
    outline-offset: -4px;
  }
}

// Top
.sliding-popup-top {
  .eu-cookie-compliance-content {
    border-top-right-radius: 0;
    border-bottom-right-radius: 1.5rem;
    box-shadow: 0 -0.375rem 0.375rem fade(@brand-primary, 0.4);

    @media (min-width: @screen-sm-min) {
      box-shadow: 0 -0.375rem 0.375rem fade(#000, 0.15);
    }
  }
}
.eu-cookie-withdraw-wrapper.sliding-popup-top {
  &,
  .eu-cookie-withdraw-banner {
    transform: scaleY(-1);
  }

  .eu-cookie-withdraw-tab {
    border-radius: 0 0 0.25rem 0.25rem;
    transform: translateX(-50%) scaleY(-1);
  }
}

// RTL
[dir="rtl"] {
  #sliding-popup {
    left: auto;
    right: 0;
    text-align: right;
  }

  .eu-cookie-compliance-content {
    border-top-right-radius: 0;
    border-top-left-radius: 1.5rem;
    text-align: right !important;
  }

  .eu-cookie-compliance-message {
    float: none !important;
  }

  .eu-cookie-compliance-categories {
    .eu-cookie-compliance-category {
      > div {
        padding-left: 0;
        padding-right: 1.125rem + 0.5rem;
      }

      input[type="checkbox"] {
        float: right;
        margin-left: 0;
        margin-right: (1.125rem + 0.5rem) * -1;
      }
    }
  }

  .eu-cookie-compliance-buttons {
    float: none !important;
  }

  .eu-cookie-compliance-close-button {
    left: 0;
    right: auto;
  }

  .sliding-popup-top {
    .eu-cookie-compliance-content {
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 1.5rem;
    }
  }
}

@media print {
  #sliding-popup {
    display: none;
  }
}

.eu-cookie-compliance-hidden {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
