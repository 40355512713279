body:not(.cke_editable) {
  padding-top: 171px;
  &.admin-menu.adminimal-menu {
    @media (min-width: @screen-lg-min) {
      #header {
        top: 29px;
        &.scrolled {
          top: -26px;
        }
      }
    }
  }
}
#cookie-compliance {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  .cookie-compliance__inner {
    margin: 20% auto;
    width: 60%;
    background: @brand-primary;
    @media (max-width: @screen-md-min) {
      width: 90%;
      padding: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
      overflow-y: scroll;
      p {
        font-size: 14px;
      }
    }
    h2 {
      color: #fff;
    }
    p {
      color: #fff;
    }
    a {
      color: #fff;
    }
    button {
      border: 1px solid #fff;
    }
  }
}
.container {
  &.md {
    max-width: 970px;
  }
}
.v-align {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.sm-marge {
  margin-left: -10px;
  margin-right: -10px;
}
.sm-gap {
  padding-left: 10px;
  padding-right: 10px;
}
.no-gap {
  padding-left: 0;
  padding-right: 0;
}
.bg {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.same-height--md {
  @media (min-width: @screen-md-min) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}
.same-height--sm {
  @media (min-width: @screen-sm-min) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

// Animate
.animated {
  .animated;
}
.os-animation {
  opacity: 0;
  @media (max-width: @screen-xs-max) {
    -webkit-animation-delay: 0s !important;
    -moz-animation-delay: 0s !important;
    -ms-animation-delay: 0s !important;
    -o-animation-delay: 0s !important;
    animation-delay: 0s !important;
  }
}

// Types
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
}
h1,
.h1 {
  color: #fff;
  font-weight: 300;
  line-height: normal;
  @media (max-width: @screen-xs-max) {
    font-size: 1.9rem;
  }
}
h2,
.h2 {
  margin-bottom: 30px;
}
h3,
.h3 {
  margin-bottom: 45px;
  line-height: 1.46em;
}

p {
  margin: 0 0 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

// Lead
p.lead {
  font-size: 1.33em;
  line-height: 1.5em;
  color: @brand-primary;
  font-weight: 300;
  margin-bottom: @line-height-computed;
}

// Evidence
p.evidence,
div.evidence {
  color: #fff;
  background-color: @brand-primary;
  padding: @line-height-computed*2;
  margin-bottom: @line-height-computed;
  @media (max-width: @screen-sm-max) {
    padding: 30px;
  }
  @media (max-width: @screen-xs-max) {
    padding: 15px;
  }

  a {
    color: #fff;
  }

  a.btn {
    background-color: #fff;
    color: @brand-primary;

    &:hover {
      background-color: darken(#fff, 10%);
    }
  }
}

// Img
img {
  max-width: 100%;
  height: auto;

  &[style*="left"] {
    margin-right: @line-height-computed;
    margin-bottom: @line-height-computed;
  }

  &[style*="right"] {
    margin-left: @line-height-computed;
    margin-bottom: @line-height-computed;
  }
}

// Table
table {
  border: 1px solid #d8d8d8;
  margin-bottom: @line-height-computed;
  width: 100%;

  th {
    background-color: @brand-primary;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
  }

  td {
    background-color: #f0f0f0;
  }

  tr.even td {
    background-color: darken(#f0f0f0, 5%);
  }

  td,
  th {
    padding: @line-height-computed / 2 @line-height-computed;
  }
}

// Iframe responsive
.iframe-embed {
  display: block;
  position: relative;
  margin-bottom: @line-height-computed;
  padding: 0;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// Boutons
.btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 18px 15px;
  border: 0;
  border-radius: 0;
  font-size: 0.88rem;
  font-weight: 900;
  letter-spacing: 1.5px;
  line-height: 1.43em;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 !important;
  .transition(all 0.2s);
  .fa {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 8px;
    font-size: 2.13rem;
    font-weight: 400;
  }
  &.btn-primary,
  &.btn-default,
  &.btn1,
  &.btn-success,
  &.btn-info {
    background-color: @brand-primary;
    color: #fff;
    &:hover {
      background-color: darken(@brand-primary, 15%);
    }
  }
  &.btn-border {
    background-color: transparent;
    border: 2px solid @brand-primary;
    color: @brand-primary;
    &:hover {
      background-color: @brand-primary;
      color: #fff;
    }
  }
  &.btn2 {
    background-color: transparent !important;
    border-bottom: 2px solid @brand-primary;
    color: @brand-primary !important;
    padding: 10px 0;
    &:hover {
      border-color: transparent;
    }
  }
  &.btn-white {
    background-color: #fff;
    color: @brand-primary;
    &:hover {
      background-color: darken(#fff, 7.5%);
    }
  }
}

.link-block {
  display: inline-block;
  padding: 10px 0;
  border-bottom: 2px solid @brand-primary;
  color: @brand-primary;
  font-family: @ff-body;
  font-size: 0.88rem;
  font-weight: 900;
  letter-spacing: 1.5px;
  line-height: 1.43em;
  text-decoration: none !important;
  text-transform: uppercase;
  .transition(all 0.2s);
  .fa {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 1.13rem;
    font-weight: 400;
  }
  &:hover {
    border-color: transparent;
    color: @brand-primary;
  }
}

.subtitle {
  display: inline-block;
  padding: 2px 5px;
  background-color: @brand-primary;
  color: #fff;
  font-family: @ff-body;
  font-size: 1.13rem;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

//item
.item {
  &--actu {
    display: block;
    margin: 20px 0;
    color: #69676a !important;
    line-height: 1.5em;
    .img-wrapper {
      padding-right: 20px;
      @media (max-width: @screen-md-max) {
        padding-right: 15px;
      }
    }
    .content {
      padding-left: 20px;
      @media (max-width: @screen-md-max) {
        padding-left: 15px;
      }
      @media (max-width: @screen-xs-max) {
        margin-top: 15px;
      }
      h3 {
        margin: 0 0 15px;
        font-family: @ff-body;
        font-size: 1.13rem;
        font-weight: 700;
        line-height: 1.5em;
        .transition(all 0.35s);
      }
      .date {
        display: block;
        margin: 0 0 10px;
        color: #a8a6ab;
        line-height: normal;
      }
    }
    &:hover {
      .content {
        h3 {
          color: @brand-red;
        }
      }
    }
    &.page-news {
      margin: 15px 0;
      .img-wrapper {
        padding: 0 15px;
      }
      .content {
        padding: 15px;
        min-height: 230px;
        @media (max-width: @screen-xs-max) {
          min-height: 0;
        }
      }
    }
  }
  &--event {
    .clearfix;
    display: block;
    margin: 10px 0;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13);
    color: #69676a !important;
    line-height: 1.5em;
    text-decoration: none !important;
    .transition(all 0.35s);
    @media (max-width: @screen-xs-max) {
      padding: 15px;
    }
    .date-wrapper {
      float: left;
      .date {
        display: block;
        position: relative;
        height: 80px;
        width: 80px;
        border: 2px solid lighten(@brand-primary, 18.5%);
        .transition(all 0.35s);
        .wrap {
          position: absolute;
          bottom: 7px;
          left: 8px;
          color: lighten(@brand-primary, 18.5%);
          font-size: 0.81rem;
          line-height: normal;
          text-align: center;
          text-transform: uppercase;
          .transition(all 0.35s);
          strong {
            display: block;
            font-size: 1.88rem;
            font-weight: 700;
          }
        }
      }
    }
    .content {
      float: left;
      padding-left: 15px;
      width: calc(~"100% - 80px");
      h3 {
        margin: 0;
        color: lighten(@brand-primary, 18.5%);
        font-family: @ff-body;
        font-size: 1rem;
        font-weight: 700;
        line-height: normal;
        .transition(all 0.35s);
      }
    }
    &:hover {
      .content {
        h3 {
          color: #fff;
        }
      }
    }
    &:not(.page-events) {
      &:hover {
        background-color: lighten(@brand-primary, 18.5%);
        color: #fff !important;
        .date-wrapper {
          .date {
            border-color: #fff;
            .wrap {
              color: #fff;
            }
          }
        }
      }
    }
    &.page-events {
      position: relative;
      padding: 0;
      background-color: #f1efe9;
      color: #373737 !important;
      box-shadow: none;
      .wrapper {
        position: relative;
        z-index: 5;
        padding: 30px;
        min-height: 330px;
        @media (max-width: @screen-xs-max) {
          padding: 15px;
          min-height: 0;
        }
      }
      .date-wrapper,
      .content {
        float: none;
      }
      .date-wrapper {
        margin-bottom: 30px;
      }
      .content {
        padding: 0;
        width: 100%;
        .cat {
          display: block;
          margin: 2px 0 15px;
          color: #b5b5b5;
          font-size: 0.9rem;
          line-height: normal;
          .transition(all 0.35s);
        }
      }
      &:hover {
        background-color: darken(@brand-primary, 10%);
        color: #fff !important;
        .cat {
          color: @brand-fadeblue;
        }
      }
      &.event-lg {
        margin: 0;
        background-color: #000;
        color: #fff !important;
        .wrapper {
          width: calc(~"33.33333333% - 15px");
          background-color: fade(#000, 50%);
          .transition(all 0.35s);
          @media (max-width: @screen-sm-max) {
            width: calc(~"50% - 15px");
          }
          @media (max-width: @screen-xs-max) {
            width: 100%;
          }
        }
        .date-wrapper {
          .date {
            border-color: #fff;
            .wrap {
              color: #fff;
            }
          }
        }
        .content {
          h3,
          .cat {
            color: #fff;
          }
        }
        &:hover {
          .wrapper {
            background-color: fade(#000, 90%);
          }
        }
      }
    }
  }
  &--service {
    margin: 25px 0;
    padding-left: 30px;
    min-height: 300px;
    color: #262626;
    line-height: 1.88em;
    @media (max-width: @screen-md-max) {
      margin: 15px 0;
      min-height: 0;
    }
    @media (max-width: @screen-sm-max) {
      padding-left: 0;
    }
    h3 {
      margin: 0 0 5px;
      padding-top: 10px;
      padding-left: 90px;
      min-height: 76px;
      background-position: left top;
      background-repeat: no-repeat;
      font-family: @ff-body;
      font-size: 1.13rem;
      font-weight: 700;
      line-height: normal;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        padding-left: 10px;
        &:before {
          content: "\f0da";
          position: absolute;
          top: 2px;
          left: 0;
          color: #c6c6c6;
          font-family: "FontAwesome";
        }
      }
    }
    a {
      text-decoration: underline;
      .fa {
        margin-right: 3px;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &.service0 {
      h3 {
        background-image: url("../img/picto-service01.png");
      }
      h3,
      a {
        color: @brand-yellow;
      }
    }
    &.service1 {
      h3 {
        background-image: url("../img/picto-service02.png");
      }
      h3,
      a {
        color: @brand-red;
      }
    }
    &.service2 {
      h3 {
        background-image: url("../img/picto-service03.png");
      }
      h3,
      a {
        color: @brand-orange;
      }
    }
    &.service3 {
      h3 {
        background-image: url("../img/picto-service04.png");
      }
      h3,
      a {
        color: @brand-blue;
      }
    }
  }
  &--atout {
    margin: 15px 0;
    padding-top: 75px;
    min-height: 160px;
    background-image: url("../img/picto-atout.png");
    background-position: left top;
    background-repeat: no-repeat;
    color: @brand-primary;
    line-height: normal;
    @media (max-width: @screen-sm-max) {
      min-height: 0;
    }
    .content {
      max-width: 190px;
      @media (max-width: @screen-sm-max) {
        max-width: none;
      }
    }
  }
  &--job {
    display: block;
    margin: 15px 0;
    padding: 30px;
    min-height: 330px;
    background-color: #f1efe9;
    color: #69676a !important;
    line-height: 1.5em;
    text-decoration: none !important;
    .transition(all 0.35s);
    @media (max-width: @screen-xs-max) {
      min-height: 0;
    }
    .content {
      h3 {
        margin: 0 0 15px;
        font-family: @ff-body;
        font-size: 1.13rem;
        font-weight: 700;
        line-height: 1.5em;
        .transition(all 0.35s);
      }
      .date {
        display: block;
        margin: 0 0 10px;
        color: #a8a6ab;
        line-height: normal;
        .transition(all 0.35s);
      }
    }
    &:hover {
      background-color: darken(@brand-primary, 10%);
      color: #fff !important;
      h3 {
        color: #fff;
      }
      .date {
        color: @brand-fadeblue;
      }
    }
  }
  &--membre {
    display: block;
    position: relative;
    margin: 15px 0;
    height: 150px;
    background-color: #fff;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 0.6;
      .transition(all 0.2s);
    }
    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &--product {
    display: block;
    margin: 10px 0;
    min-height: 415px;
    border: 1px solid #a7a7a7;
    font-size: 0.9rem;
    line-height: normal;
    text-decoration: none !important;
    .transition(all 0.2s);
    .img-wrapper {
      position: relative;
      padding: 15px;
      height: 275px;
      .wrap {
        position: relative;
        height: 100%;
        overflow: hidden;
        .transition(all 0.2s);
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          height: auto;
          max-height: 100%;
          width: auto;
          max-width: 100%;
          box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.1);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
    .content {
      padding: 5px 15px 35px;
    }
    h3 {
      margin: 0 0 3px;
      color: lighten(@brand-primary, 18.5%);
      font-family: @ff-body;
      font-size: 1.15rem;
      font-weight: 700;
      line-height: normal;
    }
    p {
      margin: 0 0 20px;
      color: #b5b5b5;
    }
    .price {
      display: block;
      color: #373737;
      text-transform: uppercase;
    }
    &:hover {
      border-color: @brand-primary;
      .img-wrapper {
        .wrap {
          -webkit-transform: scale(1.15);
          -moz-transform: scale(1.15);
          -ms-transform: scale(1.15);
          -o-transform: scale(1.15);
          transform: scale(1.15);
        }
      }
    }
  }
}

// Paragraphs
.content-texte {
  padding-top: 70px;
  padding-bottom: 95px;
  font-size: 1.15rem;
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 50px;
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    &:not(.btn):not(.pdf) {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    &.pdf {
      position: relative;
      padding-left: 20px;
      color: @brand-red;
      &:before {
        content: "\f1c1";
        position: absolute;
        top: -3px;
        left: 0;
        font-family: "FontAwesome";
      }
    }
  }
  .row {
    @media (max-width: @screen-sm-max) {
      .col-md-6 {
        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
}
.content-evidence {
  img {
    width: 490px;
    @media (max-width: @screen-xs-max) {
      margin-bottom: 30px;
    }
  }
  .subtitle {
    margin-top: 65px;
    @media (max-width: @screen-sm-max) {
      margin-top: 0;
    }
  }
  p {
    max-width: 430px;
    color: #959595;
    font-family: @ff-headings;
    font-size: 2.25rem;
    font-weight: 300;
    line-height: normal;
    @media (max-width: @screen-sm-max) {
      font-size: 1.9rem;
    }
    span {
      color: @brand-primary;
    }
  }
}
.content-evidence2 {
  position: relative;
  padding-top: 90px;
  padding-bottom: 95px;
  background-color: #f1f1f1;
  font-size: 1.15rem;
  @media (max-width: @screen-xs-max) {
    padding-bottom: 0;
  }
  .content {
    position: relative;
    z-index: 5;
  }
  .bg {
    left: 55%;
    @media (max-width: @screen-xs-max) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      margin-top: 60px;
      padding-bottom: 56.25%;
    }
  }
  .text {
    > * {
      max-width: 380px;
      @media (max-width: @screen-xs-max) {
        max-width: none;
      }
    }
  }
}
.content-cta {
  padding: 60px 0;
  background-color: @brand-primary;
  font-size: 0;
  .content,
  .cta {
    display: inline-block;
    vertical-align: middle;
    float: none;
    @media (max-width: @screen-xs-max) {
      display: block;
    }
  }
  .content {
    font-size: 1rem;
  }
  h3 {
    margin: 0;
    color: #fff;
    line-height: 1.33em;
  }
  h4 {
    margin-top: 30px;
    color: #fff;
  }
  .cta {
    text-align: right;
    @media (max-width: @screen-xs-max) {
      margin-top: 30px;
      text-align: left;
    }
  }
  .btn {
    padding: 20px;
    border-color: #fff;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
    &:hover {
      background-color: #fff;
      color: @brand-primary;
    }
  }
}
.content-galerie {
  padding: 30px 0 60px;
}

.view-filters {
  padding: 30px 0;
  background-color: #f1efe9;
  .form-group {
    margin: 0 !important;
  }
  .form-control {
    height: 40px;
    width: auto;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }
  .form-control,
  label,
  output {
    display: block;
    color: @brand-primary;
    font-size: 1.15rem;
    font-weight: 700;
    line-height: normal;
  }
}

.pagination {
  display: inline-block;
  margin: 25px 0 0;
  padding: 0;
  border-radius: 0;
  font-size: 0;
  > li {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.95rem;
    > a,
    > span {
      display: block;
      float: none;
      margin: 0 0 0 -1px;
      border: 1px solid #ddd !important;
      padding: 0;
      width: 35px;
      height: 35px;
      line-height: 33px;
      background-color: #fff;
      color: @brand-primary;
      text-decoration: none;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
    &:last-child {
      > a,
      > span {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    > .active,
    &:hover,
    &:focus {
      > a,
      > span {
        background-color: @brand-primary;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.modal {
  .close {
    position: absolute;
    top: -80px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    background-image: url(../img/icon-close--white.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    float: none;
    opacity: 1;
    filter: alpha(opacity=100);
    text-indent: -9999px;
    outline: 0 !important;
    .transition(all 0.35s);
    &:hover {
      background-image: url(../img/icon-close.png);
    }
  }
}
.modal-dialog {
  margin: 110px auto;
  width: 970px;
  max-width: 100%;
  @media (max-width: @screen-sm-max) {
    margin-bottom: 0;
  }
}
.modal-content {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  > *:not(.close) {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
  }
}
.modal-body {
  .clearfix;
  padding: 45px 15px 90px;
  .nav-tabs {
    margin: 0 0 70px;
    border: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
    text-align: center;
    > li {
      display: inline-block;
      vertical-align: middle;
      float: none;
      margin: 0;
      color: #797979;
      font-family: @ff-headings;
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
      @media (max-width: @screen-xs-max) {
        display: block;
      }
      > a {
        margin: 0;
        border: 0;
        border-bottom: 3px solid transparent;
        border-radius: 0;
        padding: 10px 0;
        background-color: transparent !important;
        color: #797979;
        line-height: normal;
        .transition(all 0.2s);
      }
      & + li {
        margin-left: 50px;
        @media (max-width: @screen-xs-max) {
          margin-left: 0;
        }
      }
      &.active,
      &:hover {
        > a {
          border: 0;
          border-bottom: 3px solid @brand-primary;
          color: @brand-primary;
        }
      }
    }
  }
}

.modal-backdrop.in {
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.node-details {
  padding-top: 70px;
  padding-bottom: 95px;
  .img-wrapper {
    img {
      float: left;
      margin: 0 30px 30px 0;
      width: 300px;
      @media (max-width: @screen-xs-max) {
        float: none;
        margin: 0 0 30px;
      }
    }
  }

  .infos {
    margin-bottom: 30px;
    font-size: 0;
    .date-wrapper,
    .cat {
      display: inline-block;
      vertical-align: top;
      @media (max-width: @screen-xs-max) {
        display: block;
      }
    }
  }
  .date-wrapper {
    margin-right: 15px;
    @media (max-width: @screen-xs-max) {
      margin-right: 0;
      margin-bottom: 15px;
    }
    .date {
      display: block;
      position: relative;
      height: 80px;
      width: 80px;
      border: 2px solid lighten(@brand-primary, 18.5%);
      .wrap {
        position: absolute;
        bottom: 7px;
        left: 8px;
        color: lighten(@brand-primary, 18.5%);
        font-size: 0.81rem;
        line-height: normal;
        text-align: center;
        text-transform: uppercase;
        strong {
          display: block;
          font-size: 1.88rem;
          font-weight: 700;
        }
      }
    }
  }
  .cat {
    display: block;
    margin: 2px 0 15px;
    color: #b5b5b5;
    font-size: 0.9rem;
    line-height: normal;
  }
  .pdf {
    position: relative;
    padding-left: 20px;
    color: @brand-red;
    &:before {
      content: "\f1c1";
      position: absolute;
      top: -3px;
      left: 0;
      font-family: "FontAwesome";
    }
  }

  & + .content-cta {
    .cta.text-center {
      text-align: center;
      @media (max-width: @screen-xs-max) {
        margin-top: 0;
      }
    }
  }
}

.panel-group {
  .panel {
    border-bottom: 1px solid #e6e6e6;
  }
  .panel-title {
    margin: 0;
    padding: 0;
    color: @brand-primary;
    font-size: 1.15rem;
    font-family: @ff-headings;
    font-weight: 400;
    line-height: normal;
    a {
      display: block;
      position: relative;
      padding: 30px 15px;
      padding-right: 45px;
      color: @brand-primary;
      text-decoration: none;
      .transition(all 0.2s);
      &:after {
        content: "\f067";
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -12px;
        font-family: "FontAwesome";
        font-size: 1.5rem;
        line-height: 1em;
      }
      &:not(.collapsed) {
        &:after {
          content: "\f068";
        }
      }
      &:hover {
        color: @brand-red;
      }
    }
  }
  .panel-body {
    padding: 10px 15px 40px;
  }
}

body.page-user,
body.page-search404,
body.page-search {
  #main {
    padding-top: 45px;
    padding-bottom: 125px;
  }
}

body.page-user {
  #login-register {
    @media (max-width: @screen-sm-max) {
      margin-top: 60px;
    }
  }
}

body.page-search404,
body.page-search {
  #main {
    padding-top: 45px;
    padding-bottom: 125px;
    .search-form {
      .input-group {
        font-size: 0;
        .form-control,
        .input-group-btn {
          display: inline-block;
          vertical-align: middle;
        }
        .form-control {
          width: calc(~"100% - 50px");
        }
        .input-group-btn {
          .btn {
            margin: 0;
            padding: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;
          }
        }
      }
    }
    .search-results {
      .search-result {
        h3 {
          margin: 0;
        }
        & + .search-result {
          margin-top: 30px;
        }
      }
    }
  }
}

.clear {
  clear: both;
}
