// Job
body.page-jobs {
  .view {
    padding-bottom: 125px;
  }
  .view-content {
    padding-top: 45px;
  }
}

body.node-type-job {
	form {
		> div {
			> #edit-upload-ajax-wrapper {
				position: relative;
			    min-height: 1px;
			    padding-left: 15px;
			    padding-right: 15px;
				@media (min-width: @screen-md-min) {
					float: left;
				    width: 100%;
				} 
			}
		}
	}
}