// Service
body.node-type-service {
  .cta-contact {
    /* top: auto;
    bottom: 340px; */
    top: 430px;
  }
}

#service-content {
  padding-top: 65px;
  padding-bottom: 100px;
  color: #5c5c5c;
  font-size: 1.15rem;
}

#services {
  padding-top: 65px;
  padding-bottom: 70px;
  h2 {
    margin: 0 0 65px;
    color: @brand-red;
    text-align: center;
  }
  .wrapper {
    .clearfix;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    background-color: #f3f6f9;
    .aside, .content {
      float: left;
    }
    .aside {
      width: 370px;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      @media (max-width: @screen-md-max) {
        width: 300px;
      }
      @media (max-width: @screen-sm-max) {
        width: 285px;
      }
      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
      form {
        border-bottom: 1px solid #e6e6e6;
        padding: 25px 20px;
        background-color: #e3e8ee;
        @media (max-width: @screen-sm-max) {
          padding-left: 15px;
          padding-right: 15px;
        }
        input.throbbing {
          background-position: right 5px center;
          background-repeat: no-repeat;
        }
        .form-group {
          margin: 0 !important;
          .input-group-addon {
            display: none;
          }
          &.form-item-search-pages .input-group {
            position: relative;
            &:after {
              content: '\f002';
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -8px;
              color: #8e8e8e;
              font-family: 'FontAwesome';
              font-size: 0.95rem;
              font-weight: 400;
              line-height: 1em;
            }
          }
        }
        .form-control {
            padding: 0 20px;
            background-color: #fff;
            border-radius: 4px;
        }
        .form-control, label, output {
            color: #afafaf;
            font-style: italic;
            line-height: normal;
        }
        ::-webkit-input-placeholder {
          color: #afafaf;
        }
        ::-moz-placeholder {
          color: #afafaf;
        }
        :-ms-input-placeholder {
          color: #afafaf;
        }
        :-moz-placeholder {
          color: #afafaf;
        }
      }
      ul {
        margin: 0;
        border: 0;
        padding: 0;
        list-style: none;
        li {
          float: none;
          margin: 0;
          border-bottom: 1px solid #e6e6e6;
          a {
            display: block;
            position: relative;
            margin: 0;
            border: 0;
            border-radius: 0;
            padding: 22px 25px;
            padding-right: 45px;
            background-color: #fff;
            color: @brand-primary;
            font-family: @ff-headings;
            font-size: 1.15rem;
            font-weight: 400;
            line-height: 1.4em;
            text-decoration: none;
            .transition(all .35s);
            @media (max-width: @screen-sm-max) {
              padding-left: 15px;
              padding-right: 15px;
            }
            .fa {
              position: absolute;
              top: 50%;
              right: 25px;
              margin-top: -12px;
              color: #fff;
              font-size: 1.5rem;
              line-height: 1em;
            }
          }
          &:hover, &.active {
            a {
              background-color: @brand-primary;
              color: #fff;
            }
          }
        }
      }
      .contact {
        padding: 25px;
        @media (max-width: @screen-sm-max) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      .btn {
        display: block;
        padding: 18px 15px;
        background-color: #fff;
        border: 2px solid @brand-red;
        color: @brand-red;
        font-size: 0.95rem;
        font-weight: 700;
        letter-spacing: .5px;
        line-height: 1.65em;
        &:hover {
          background-color: @brand-red;
          color: #fff;
        }
      }
    }
    .content {
      padding: 55px 15px 70px;
      width: calc(~"100% - 370px");
      border: 1px solid #e6e6e6;
      border-left: 0;
      @media (max-width: @screen-md-max) {
        width: calc(~"100% - 300px");
      }
      @media (max-width: @screen-sm-max) {
        width: calc(~"100% - 285px");
      }
      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
      .wrap {
        margin-left: auto;
        margin-right: auto;
        max-width: 730px;
      }
      h3 {
        margin: 0 0 70px;
        color: @brand-red;
        @media (max-width: @screen-xs-max) {
          margin-bottom: 30px;
        }
      }
      .hightlight {
        margin-bottom: 20px;
        padding: 30px 35px 35px;
        background-color: @brand-red;
        box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1);
        @media (max-width: @screen-xs-max) {
          padding-left: 15px;
          padding-right: 15px;
        }
        h4 {
          margin: 0 0 25px;
          color: #fff;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          @media (min-width: @screen-md-min) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
          }
          li {
            display: inline-block;
            position: relative;
            margin: 0 0 5px;
            padding-left: 10px;
            width: 100%;
            color: #fff;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.15em;
            &:before {
              content: '\f0da';
              position: absolute;
              top: 0;
              left: 0;
              font-family: 'FontAwesome';
            }
            a {
              color: #fff;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      ul.cat2 {
        margin: 0 -15px;
        padding: 0;
        list-style: none;
        font-size: 0;
        @media (max-width: @screen-sm-max) {
          margin-left: 0;
          margin-right: 0;
        }
        > li {
          display: inline-block;
          vertical-align: top;
          position: relative;
          margin: 15px;
          padding: 27px 35px;
          padding-right: 45px;
          width: calc(~"50% - 30px");
          background-color: #fff;
          box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1);
          color: @brand-primary;
          font-family: @ff-headings;
          font-size: 1.15rem;
          font-weight: 400;
          line-height: normal;
          @media (max-width: @screen-md-max) {
            padding-left: 30px;
          }
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
          @media (max-width: @screen-xs-max) {
            padding-left: 15px;
            padding-right: 30px;
          }
          > a {
            color: @brand-primary;
            text-decoration: none;
            &.expand {
              &:after {
                content: '\f067';
                position: absolute;
                top: 30px;
                right: 25px;
                font-family: 'FontAwesome';
                font-size: 1.5rem;
                line-height: 1em;
                @media (max-width: @screen-xs-max) {
                  right: 15px;
                }
              }
            }
            &:hover {
              text-decoration: underline;
            }
          }
          &.open {
            > a.expand {
              &:after {
                content: '\f068'
              }
            }
          }
          ul {
            display: none;
            margin: 0;
            padding: 0;
            list-style: none;
            > li {
              position: relative;
              padding-left: 10px;
              color: #585858;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.15em;
              &:before {
                content: '\f0da';
                position: absolute;
                top: 0;
                left: 0;
                font-family: 'FontAwesome';
              }
              > a {
                color: #585858;
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
              }
              &.open {
                &:before {
                  content: '\f0d7';
                }
              }
              & + li {
                margin-top: 5px;
              }
            }
          }
          > ul {
            margin: 30px 0 35px;
          }
        }
      }
    }
  }
}

#chiffres {
  position: relative;
  background-color: #f5f5f5;
  .chiffres {
    padding-top: 65px;
    padding-right: 60px;
    padding-bottom: 65px;
    line-height: normal;
    .subtitle {
      margin: 0 0 100px;
      background-color: @brand-red;
      @media (max-width: @screen-sm-max) {
        margin-bottom: 60px;
      }
    }
    h2:not(.subtitle) {
      margin: 0 0 20px;
      color: @brand-red;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: normal;
    }
    @media (max-width: @screen-sm-max) {
      .row {
        .col-md-6 {
          &:first-child {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  .contact {
    padding-top: 145px;
    padding-left: 75px;
    padding-bottom: 100px;
    color: #fff;
    font-size: 1.5rem;
    font-family: @ff-headings;
    font-weight: 300;
    line-height: normal;
    @media (max-width: @screen-sm-max) {
      padding: 90px 15px;
    }
    @media (max-width: @screen-xs-max) {
      position: relative;
      background-color: shade(@brand-red, 15%);
      &:before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(../img/bg-textures.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        mix-blend-mode: soft-light;
        opacity: .59;
        z-index: -1px;
      }
    }
    p {
      margin: 0 0 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a:not(.btn) {
      color: #fff;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .btn2 {
      margin-top: 80px;
      border-color: #fff;
      color: tint(@brand-red, 75%) !important;
      font-family: @ff-body;
      font-size: 1rem;
      font-weight: 700;
      line-height: normal;
      &:before {
        content: '\f003';
        margin-right: 10px;
        color: #fff !important;
        font-family: 'FontAwesome';
        font-size: 1.4rem;
        font-weight: 400;
      }
      &:hover {
        border-color: transparent;
      }
    }
  }
  .content {
    position: relative;
    z-index: 5;
  }
  .bg {
    left: 55%;
    background-color: shade(@brand-red, 15%);
    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(../img/bg-textures.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      mix-blend-mode: soft-light;
      opacity: .59;
    }
    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }
}

.service-color(@color) {
  #services {
    h2 {
      color: @color;
    }
    .wrapper {
      .aside {
        .btn {
          border: 2px solid @color;
          color: @color;
          &:hover {
            background-color: @color;
            color: #fff;
          }
        }
      }
      .content {
        h3 {
          color: @color;
        }
        .hightlight {
          background-color: @color;
        }
      }
    }
  }

  #chiffres {
    .chiffres {
      .subtitle {
        background-color: @color;
      }
      h2:not(.subtitle) {
        color: @color;
      }
    }
    .contact {
      @media (max-width: @screen-xs-max) {
        position: relative;
        background-color: shade(@color, 15%);
      }
      .btn2 {
        color: tint(@color, 75%) !important;
      }
    }
    .bg {
      background-color: shade(@color, 15%);
    }
  }
}

.service-cat4 {
  .service-color(@brand-yellow);
}
.service-cat5 {
  .service-color(@brand-red);
}
.service-cat6 {
  .service-color(@brand-orange);
}
.service-cat7 {
  .service-color(@brand-blue);
}
