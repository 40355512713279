// Extranet
#cat-extranet {
  background-color: #f2f2f2;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    text-align: center;
    li {
      display: inline-block;
      vertical-align: middle;
      a {
        padding: 15px;
        background-color: transparent;
        color: #747474;
        font-family: @ff-body;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.45em;
        &:hover {
          color: @brand-primary;
        }
      }
      @media (max-width: @screen-xs-max) {
        &:nth-child(odd) {
          border-right: 2px solid #DDD;
        }
      }
    }
  }
}

#banner-extranet {
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: @brand-primary;
  text-align: center;
  h1 {
    margin: 0 0 45px;
    font-size: 2.25rem;
  }
  .search-form {
    font-size: 0;
    .form-group {
      display: inline-block;
      vertical-align: middle;
      margin: 0 !important;
      background-color: #fff;
      @media (max-width: @screen-xs-max) {
        display: block;
      }
      &.form-actions {
        background-color: transparent;
      }
      &.form-type-select {
        padding: 0 20px;
      }
      &.form-type-textfield {
        padding: 0 30px;
        width: 400px;
        max-width: 100%;
        border-radius: 5px 0 0 5px;
        @media (max-width: @screen-xs-max) {
          border-radius: 5px 5px 0 0;
          width: 100%;
        }
      }
    }
    .form-control {
        height: 75px;
        width: 100%;
        padding: 0;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        -webkit-box-shadow: none!important;
        -moz-box-shadow: none!important;
        box-shadow: none!important;
    }
    .form-control, label, output {
        display: block;
        color: @brand-primary;
        font-size: 1rem;
        line-height: normal;
    }
    ::-webkit-input-placeholder {
      color: #adadad;
    }
    ::-moz-placeholder {
      color: #adadad;
    }
    :-ms-input-placeholder {
      color: #adadad;
    }
    :-moz-placeholder {
      color: #adadad;
    }
    .form-submit {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        width: 75px;
        height: 75px;
        background-color: #fff;
        background-image: url(../img/icon-search.png);
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0 5px 5px 0;
        text-indent: -9999px;
        .transition(all .2s);
        @media (max-width: @screen-xs-max) {
          border-radius: 0 0 5px 5px;
          width: 100%;
          background-size: 20px 20px;
          height: 55px
        }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -20px;
          height: 40px;
          width: 1px;
          background-color: fade(#000, 10%);
          @media (max-width: @screen-xs-max) {
            top: 0;
            left: 50%;
            margin: 0 0 0 -20px;
            width: 40px;
            height: 1px;
          }
        }
        &:hover {
          background-color: @brand-red;
          background-image: url(../img/icon-search--white.png);
        }
    }
  }
}

#extranet-highlight {
  padding-top: 45px;
  .wrapper {
    padding: 35px 15px 40px;
    border: 5px solid #e6e6e6;
    > * {
      margin-left: auto;
      margin-right: auto;
      max-width: 700px;
    }
  }
  h2 {
    margin: 0 0 30px;
    color: @brand-red;
    font-size: 1.15rem;
    line-height: normal;
    text-align: center;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @media (min-width: @screen-sm-min) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    li {
      display: inline-block;
      position: relative;
      margin: 0 0 5px;
      padding-left: 10px;
      width: 100%;
      line-height: 1.15em;
      &:before {
        content: '\f0da';
        position: absolute;
        top: 0;
        left: 0;
        color: @brand-red;
        font-family: 'FontAwesome';
      }
      a {
        color: @brand-red;
      }
    }
  }
}


#extranet-categories {
  padding-top: 15px;
  padding-bottom: 5px;
  .cat {
    display: block;
    margin: 15px 0;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    text-align: center;

    h3 {
      margin: 0;
      color: @brand-primary;
      font-family: @ff-body;
      font-size: 0.75rem;
      font-weight: 900;
      letter-spacing: 1.5px;
      line-height: normal;
      text-transform: uppercase;
      .fa {
        margin-left: 15px;
        font-size: 0.8rem;
      }
    }

    ul {
      margin: 0 0 15px;
      padding: 0;
      list-style: none;
      li {
        font-size: 0.9rem;
        line-height: normal;
        a {
          color: lighten(@brand-primary, 12%);
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        & + li {
          margin-top: 10px;
        }
      }
    }

    .more {
      color: lighten(@brand-primary, 12%);
      font-size: 0.75rem;
      font-weight: 900;
      letter-spacing: 1.5px;
      line-height: 1.25em;
      text-transform: uppercase;
      .fa {
        margin-left: 15px;
        font-size: 0.8rem;
      }
    }

    &.cat11, &.cat250, &.cat258, &.cat263, &.cat267 {
      .front {
        background-image: url(../img/icon-cat11.png);
      }
    }
    &.cat12 {
      .front {
        background-image: url(../img/icon-cat12.png);
      }
    }
    &.cat13 {
      .front {
        background-image: url(../img/icon-cat13.png);
      }
    }
    &.cat14, &.cat252 {
      .front {
        background-image: url(../img/icon-cat14.png);
      }
    }
    &.cat15 {
      .front {
        background-image: url(../img/icon-cat15.png);
      }
    }
    &.cat16, &.cat251 {
      .front {
        background-image: url(../img/icon-cat16.png);
      }
    }
    &.cat17 {
      .front {
        background-image: url(../img/icon-cat17.png);
      }
    }
    &.cat18 {
      .front {
        background-image: url(../img/icon-cat18.png);
      }
    }
    &.cat19 {
      .front {
        background-image: url(../img/icon-cat19.png);
      }
    }

    &, .front, .back {
      width: 100%;
      height: 300px;
    }
    .card {
      position: relative;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      .transition(all .35s);
    }
    .front, .back {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      border: 2px solid #e6e6e6;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      .transition(all .35s);
    }
    .front {
      z-index: 2;
      background-repeat: no-repeat;
      background-position: center top 75px;
      -webkit-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      transform: rotateY(0deg);
      h3 {
        position: absolute;
        top: 195px;
        left: 0;
        padding: 0 30px;
        width: 100%;
      }
    }
    .back {
      padding: 40px 30px;
      -webkit-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
      h3 {
        margin-bottom: 45px;
      }
    }
    &:hover {
      .back {
        -webkit-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }
      .front {
       -webkit- transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        transform: rotateY(180deg);
      }
    }
  }
}


body.page-extranet.not-logged-in {
  #main {
    .container;
    padding-top: 45px;
    padding-bottom: 125px;
  }
}

.term-listing-heading .categories {
  ul {
    padding: 0;
    .subcategory, .page {
      height: 300px;
      text-align: center;
      list-style: none;
      display: inline-table;
      width: 33.33333%;
      float: left;
      padding: 15px;
      a {
        margin: 0;
        color: #005494;
        font-family: Lato,sans-serif;
        font-size: .75rem;
        font-weight: 900;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        position: relative;
        border: 2px solid #e6e6e6;
        width: 100%;
        height: 100%;
        display: block;
        padding-top: 120px;
        padding-left: 15px;
        padding-right: 15px;
        transition: 0.3s ease-in-out;
        text-decoration: none;
        .fa {
          margin-left: 15px;
          font-size: .8rem;
        }
        &:hover {
          background: #005494;
          color: #FFF;
          border: 2px solid #005494;
        }
      }
    }
  }
  @media only screen and (min-width : 320px) and (max-width : 990px) {
    ul {
      .subcategory {
        width: 100%;
      }
    }
  }
}
