// user
#user-profile-form {
  .form-submit {
    .glyphicon {
      display: none;
    }
  }
}

body.page-user {
  .profile {
    padding-top: 20px;
    /*display: grid;
    grid-template-columns: repeat(2, 1fr);
    > h3, > dl {
      grid-column-start: 1;
      grid-column-end: span 2;
    }*/
    > h3 {
      margin-bottom: 0;
    }
    > .field, > dl {
      margin-bottom: 20px;
    }
    > .field {
      font-size: 0.875rem;
      line-height: 1.5;
      &:last-of-type {
      }
      /*> div {
        display: inline-block;
        vertical-align: top;
        @media (max-width: @screen-xs-max) {
          display: block;
        }
        & + div {
          margin-left: 10px;
          @media (max-width: @screen-xs-max) {
            margin-left: 0;
            margin-bottom: 10px;
          }
        }
      }*/
      .field-label {
        color: @brand-primary;
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}
.page-user-register {
  .addressfield-container-inline {
    > div.form-item {
      margin-right: 10px !important;
    }
  }
}
